
import CheabooOrdersImportInput from "./CheabooOrdersImport/Input.svelte"; 
import CheabooOrdersImportResult from "./CheabooOrdersImport/Result.svelte";
export const TASK_TYPE_CHEABOO_ORDERS_IMPORT = {
    text: 'Cheaboo Orders Import',
    type: 'CheabooOrdersImport',
    triggerPath: 'trigger/CheabooOrdersImport',
    inputComponent: CheabooOrdersImportInput,
    resultComponent: CheabooOrdersImportResult
};

import CheabooStockImportInput from "./CheabooStockImport/Input.svelte"; 
import CheabooStockImportResult from "./CheabooStockImport/Result.svelte";
export const TASK_TYPE_CHEABOO_STOCK_IMPORT = {
    text: 'Cheaboo Stock Import',
    type: 'CheabooStockImport',
    triggerPath: 'trigger/CheabooStockImport',
    inputComponent: CheabooStockImportInput,
    resultComponent: CheabooStockImportResult
};

import OrderReferenceDetailsExportInput from "./OrderReferenceDetailsExport/Input.svelte"; 
import OrderReferenceDetailsExportkResult from "./OrderReferenceDetailsExport/Result.svelte";
export const TASK_TYPE_ORDER_REFERENCE_DETAILS_EXPORT = {
    text: 'Order Reference Details Export',
    description: 'Export der Bestellreferenz Details',
    type: 'OrderReferenceDetailsExport',
    triggerPath: 'trigger/OrderReferenceDetailsExport',
    inputComponent: OrderReferenceDetailsExportInput,
    resultComponent: OrderReferenceDetailsExportkResult
};

import VlbDeltaaImportInput from "./VlbDeltaaImport/Input.svelte"; 
import VlbDeltaaImportResult from "./VlbDeltaaImport/Result.svelte";
export const TASK_TYPE_VLB_DELTA_IMPORT = { 
    text: 'VLB Delta Import',
    type: 'VlbDeltaImport',
    description: 'Fragt die neuen / geänderten EANs bei VLB ab und trägt diese auf der Whitelist ein',
    triggerPath: 'trigger/VlbDeltaImport',
    inputComponent: VlbDeltaaImportInput,
    resultComponent: VlbDeltaaImportResult
};

import MaintenanceCleanupInput from "./MaintenanceCleanup/Input.svelte"; 
import MaintenanceCleanupResult from "./MaintenanceCleanup/Result.svelte";
export const TASK_TYPE_MAINTENANCE_CLEANUP = { 
    text: 'Maintenance Cleanup',
    type: 'MaintenanceCleanup',
    description: 'bereinigt alte Daten (>365 Tage) aus der DB ',
    triggerPath: 'trigger/MaintenanceCleanup',
    inputComponent: MaintenanceCleanupInput,
    resultComponent: MaintenanceCleanupResult
};

import MaintenanceAbandonedStockInput from "./MaintenanceAbandonedStock/Input.svelte"; 
import MaintenanceAbandonedStockResult from "./MaintenanceAbandonedStock/Result.svelte";
export const TASK_TYPE_MAINTENANCE_ABANDONED_STOCK = { 
    text: 'Maintenance AbandonedStock',
    type: 'MaintenanceAbandonedStock',
    description: 'setzt Bestand = 0 bei SKUs die seit 7 Tagen nicht mehr aktualisiert wurden (Annahme die wurden gelöscht)',
    triggerPath: 'trigger/MaintenanceAbandonedStock',
    inputComponent: MaintenanceAbandonedStockInput,
    resultComponent: MaintenanceAbandonedStockResult
};

import MaintenanceWhitelistInput from "./MaintenanceWhitelist/Input.svelte"; 
import MaintenanceWhitelistResult from "./MaintenanceWhitelist/Result.svelte";
export const TASK_TYPE_MAINTENANCE_WHITELIST = { 
    text: 'Maintenance Whitelist',
    type: 'MaintenanceWhitelist',
    description: 'Aktualisiert den Whiteliste Score MWS',
    triggerPath: 'trigger/MaintenanceWhitelist',
    inputComponent: MaintenanceWhitelistInput,
    resultComponent: MaintenanceWhitelistResult
};

import FixedPriceConfigurationInput from "./FixedPriceConfiguration/Input.svelte"; 
import FixedPriceConfigurationResult from "./FixedPriceConfiguration/Result.svelte";
export const TASK_TYPE_FIXED_PRICE_CONFIGURATION = {
    text: 'Fixed Price Upload',
    description: 'Fixierte Preise hochladen',
    type: 'FixedPriceConfiguration',
    triggerPath: 'trigger/FixedPriceConfiguration',
    inputComponent: FixedPriceConfigurationInput,
    resultComponent: FixedPriceConfigurationResult
};

import FallbackValueImportInput from "./FallbackValueImport/Input.svelte"; 
import FallbackValueImportResult from "./FallbackValueImport/Result.svelte";
export const TASK_TYPE_FALLBACK_VALUE_IMPORT = {
    text: 'Fallback Value Import',
    type: 'FallbackValueImport',
    triggerPath: 'trigger/FallbackValueImport',
    inputComponent: FallbackValueImportInput,
    resultComponent: FallbackValueImportResult
};

import VlbScoreImportInput from "./VlbScoreImport/Input.svelte"; 
import VlbScoreImportResult from "./VlbScoreImport/Result.svelte";
export const TASK_TYPE_VLB_SCORE_IMPORT = {
    text: 'Vlb Score Import',
    type: 'VlbScoreImport',
    triggerPath: 'trigger/VlbScoreImport',
    inputComponent: VlbScoreImportInput,
    resultComponent: VlbScoreImportResult
};

import WhitelistScoreImportInput from "./WhitelistScoreImport/Input.svelte"; 
import WhitelistScoreImportResult from "./WhitelistScoreImport/Result.svelte";
export const TASK_TYPE_WHITELIST_SCORE_IMPORT = {
    text: 'Whitelist Score Import',
    type: 'WhitelistScoreImport',
    triggerPath: 'trigger/WhitelistScoreImport',
    inputComponent: WhitelistScoreImportInput,
    resultComponent: WhitelistScoreImportResult
};

import FbaSelectionTransferInput from "./FbaSelectionTransfer/Input.svelte"; 
import FbaSelectionTransferResult from "./FbaSelectionTransfer/Result.svelte";
export const TASK_TYPE_FBA_SELECTION_TRANSFER = {
    text: 'FbaSelection Transfer',
    description: 'Übertragen der FBA Selection Reports der letzten 24 als Invloader',
    type: 'FbaSelectionTransfer',
    triggerPath: 'trigger/FbaSelectionTransfer',
    inputComponent: FbaSelectionTransferInput,
    resultComponent: FbaSelectionTransferResult
};

export const TASK_TYPES = [
    TASK_TYPE_CHEABOO_ORDERS_IMPORT,
    TASK_TYPE_CHEABOO_STOCK_IMPORT,
    TASK_TYPE_ORDER_REFERENCE_DETAILS_EXPORT, 
    TASK_TYPE_VLB_DELTA_IMPORT,   
    TASK_TYPE_VLB_SCORE_IMPORT,
    TASK_TYPE_MAINTENANCE_CLEANUP,
    TASK_TYPE_MAINTENANCE_ABANDONED_STOCK,   
    TASK_TYPE_MAINTENANCE_WHITELIST,
    TASK_TYPE_FIXED_PRICE_CONFIGURATION,
    TASK_TYPE_FALLBACK_VALUE_IMPORT,
    TASK_TYPE_WHITELIST_SCORE_IMPORT,
    TASK_TYPE_FBA_SELECTION_TRANSFER
];