<Base>
    <svelte:fragment slot="main">
    {#if inputDocument} 
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    Parameter
                </CardTitle>                
            </CardHeader>
            <CardBody>
            </CardBody>
        </Card>    
    {/if}
    {#if outputDocument}
        <Card class="mb-1">
            <CardHeader>
                <CardTitle>
                    betrachtete Reports
                </CardTitle>                
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive 
                    rows={(outputDocument?.items || [])} 
                    columns={importedItemsColumns} 
                    sortDirection="desc" 
                    sortByColumn="finishedTimeStamp" 
                    showDownloadButtonXlsx="true"
                    showPageSizeDropDown="true" 
                    on:row-click={navigateToItem}>
                    <div slot="custom-slot-1" let:row={row} let:column={column}>
                        { row[column.propertyName] ? row[column.propertyName] : "-" }
                    </div>
                </TableWithPaginationAndSort>
            </CardBody>
        </Card>      
    {/if}
    </svelte:fragment>
</Base>

<script>
 
    import { push } from 'svelte-spa-router'
    import Base from "../../Base.svelte";    
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    
    import { 
        Card, 
        CardBody, 
        CardHeader, 
        CardTitle, 
        CardSubtitle 
    } from '@sveltestrap/sveltestrap';

    import { 
        FormGroup, 
        Input, 
        Label 
    } from '@sveltestrap/sveltestrap';

    export let inputDocument = null;
    export let outputDocument = null;

    let importedItemsColumns = [
        { 
            displayName: 'Zeitpunkt Report fertig', 
            propertyName: 'finishedTimeStamp',
            format: 'DATETIME'
        }, 
        { 
            displayName: 'übertragene Zeilen', 
            propertyName: 'numItems', 
            format: 'NUMBER_0_DECIMALS'
        }, 
        { 
            displayName: 'Meldung', 
            propertyName: 'message',
            customSlot: '1'
        }
    ];

    function navigateToItem(onRowClickEvent) {
        const { reportId } = onRowClickEvent?.detail?.row;
        if (reportId) {
            push(`/reports/${reportId}`);
        }
    }

</script>