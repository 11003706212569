<Base>
    <svelte:fragment slot="main">
    {#if queryResultData}
        <Card>
            <CardHeader>
                <Row>
                    <Col class="d-grid align-items-center">
                        <CardTitle class="mb-0">
                            Berichte
                        </CardTitle>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup>
                            <ButtonDropdown size="sm">
                                <DropdownToggle color="primary">
                                    <Icon name="plus" />
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem header>Bericht anfordern</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('NEW_NO_BUYBOX') }>NEW_NO_BUYBOX</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('FIXED_PRICES') }>FIXED_PRICES</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('TARGET_ACTUAL_PRICE_DIFF') }>TARGET_ACTUAL_PRICE_DIFF</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('FBA_SELECTION') }>FBA_SELECTION</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('FBA_SELECTION_SCHNELLDREHER') }>FBA_SELECTION_SCHNELLDREHER</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('FBM_SELECTION') }>FBM_SELECTION</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('PRICE_TREND') }>PRICE_TREND</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('PRICE_ERROR') }>PRICE_ERROR</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('MISSING_VLB_PRICEDATA') }>MISSING_VLB_PRICEDATA</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('VALIDATE_FIXEDPRICE_01') }>VALIDATE_FIXEDPRICE_01</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('VALIDATE_FIXEDPRICE_02') }>VALIDATE_FIXEDPRICE_02</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('VALIDATE_FIXEDPRICE_03') }>VALIDATE_FIXEDPRICE_03</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('VALIDATE_FIXEDPRICE_04') }>VALIDATE_FIXEDPRICE_04</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('SKUS_WITHOUT_STOCK') }>SKUS_WITHOUT_STOCK</DropdownItem>
                                    <DropdownItem on:click={() => triggerNewReport('LAGERBESTAND_OHNE_AKTIVES_ANGEBOT') }>LAGERBESTAND_OHNE_AKTIVES_ANGEBOT</DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                            <Button color="secondary" size="sm" on:click={() => refreshList() }>
                                <Icon name="arrow-repeat" />
                            </Button>                             
                        </ButtonGroup>
                    </Col>
                </Row>     
            </CardHeader>
            <CardBody>
                <TableWithPaginationAndSort responsive rows={(queryResultData?.items || [])} columns={tableColumns} sortDirection="desc" sortByColumn="submitted" id="reports-list" on:row-click={navigateToItem} filterGlobalEnabled="true">
                    <div slot="custom-slot-2" let:row={row} let:column={column}>
                        {@const p1 = row?.progressCount ?? 0}
                        {@const p2 = row?.progressMax ?? 100}
                        {@const px = ((p1/p2)*100.0).toFixed(1)}                        
                        {#if row[column.propertyName] == 'SUBMITTED'}
                            <Icon name="pause-circle" class="text-secondary" />
                        {:else if row[column.propertyName] == 'INPROGRESS'}
                            <Progress animated value={p1} max={p2} class="mt-1 w-100 position-relative">
                                <div class="text-center w-100 position-absolute">{px}%</div>
                            </Progress>
                        {:else if row[column.propertyName] == 'FINISHED'}
                            <Icon name="check-circle" class="text-success" />
                        {:else if row[column.propertyName] == 'FAILED'}
                            <Icon name="x-circle" class="text-danger" />
                        {/if}
                    </div>   
                    <div slot="custom-slot-3" let:row={row} let:column={column} on:click|stopPropagation>
                        <Button size="sm" color="danger" on:click={deleteJob(row?.id)} disabled={row?.status == 'INPROGRESS'}>
                            <Icon name="trash" />
                        </Button>
                    </div>                                    
                </TableWithPaginationAndSort>
            </CardBody>
        </Card>
    {/if}
    <MISSING_VLB_PRICEDATA_MODAL bind:this={modal_missing_vlb_price_data} />
    <NEW_NO_BUYBOX_MODAL bind:this={modal_new_no_buybox_data} />
    <FBA_SELECTION_MODAL bind:this={modal_fba_selection_data} />
    </svelte:fragment>
</Base>

<script>

    import { getReportList, triggerReport, deleteReportById } from "@/clients/apdbClient";
    import { onMount, onDestroy } from 'svelte';
    import { push } from 'svelte-spa-router'
    import Base from "../Base.svelte";
    import TableWithPaginationAndSort from "@/components/table/TableWithPaginationAndSort.svelte";
    import { Card, CardBody, CardHeader, CardTitle, Icon } from '@sveltestrap/sveltestrap';
    import { ButtonDropdown, Button, ButtonGroup } from '@sveltestrap/sveltestrap';
    import { DropdownItem, DropdownMenu, DropdownToggle } from '@sveltestrap/sveltestrap';
    import { Row, Col } from '@sveltestrap/sveltestrap'; 
    import { Progress } from '@sveltestrap/sveltestrap';     
    import MISSING_VLB_PRICEDATA_MODAL from "./_MISSING_VLB_PRICEDATA.modal.svelte";
    import NEW_NO_BUYBOX_MODAL from "./_NEW_NO_BUYBOX.modal.svelte";
    import FBA_SELECTION_MODAL from "./_FBA_SELECTION.modal.svelte"

    let refrehListInterval;
    onMount(async () => {  
        refrehListInterval = setInterval(refreshList, 5000);
        return refreshList();
    });

    onDestroy(async () => {
        clearInterval(refrehListInterval);
    });

    function navigateToItem(onRowClickEvent) {
        const {id } = onRowClickEvent?.detail?.row;
        push(`/reports/${id}`);
    }
    
    let queryResultData = null;
    let queryResultStatus = null;

    let tableColumns = [
        { 
            displayName: 'Status', 
            propertyName: 'status', 
            customSlot: '2', style: 
            'width: 80px' 
        },
        { 
            displayName: 'Art', 
            propertyName: 'type' 
        },
        { 
            displayName: 'angelegt', 
            propertyName: 'submitted', 
            format: 'DATETIME', 
            style: 'width: 180px' 
        },
        { 
            displayName: 'gestartet', 
            propertyName: 'started', 
            format: 'DATETIME', 
            style: 'width: 180px' 
        },
        { 
            displayName: 'fertig', 
            propertyName: 'finished', 
            format: 'DATETIME', 
            style: 'width: 180px' 
        },
        { 
            displayName: '', 
            propertyName: '', 
            customSlot: '3', 
            style: 'width: 50px; padding: 0.25rem 0; text-align: right;'
        }
    ];

    async function refreshList() {
        return getReportList()
            .then(p => {
                queryResultData = p.queryResultData;
                queryResultStatus = p.queryResultStatus;
            });
    }

    let modal_missing_vlb_price_data;
    let modal_new_no_buybox_data;
    let modal_fba_selection_data;

    async function triggerNewReport(jobType) {
        
        let inputDocument = {};
        if (jobType == 'MISSING_VLB_PRICEDATA') {
            inputDocument = await modal_missing_vlb_price_data.showDialog();
        }
        if (jobType == 'NEW_NO_BUYBOX') {
            inputDocument = await modal_new_no_buybox_data.showDialog();
        }
        if (jobType == 'FBA_SELECTION') {
            inputDocument = await modal_fba_selection_data.showDialog();
        }
        return triggerReport({
            jobType,
            name: ' ',
            inputDocument
        });
    }

    async function deleteJob(jobId) {
        return deleteReportById(jobId);
    };

</script>